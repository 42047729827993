@tailwind base;
@tailwind components;
@tailwind utilities;
@notosans-fontface-path: "fonts/Noto/";
@import "notosans-fontface/css/notosans-fontface";
@import "react-html5-camera-photo/build/css/index.css";

body {
  width: 100vw;
  font-family: "Noto Sans", sans-serif;
}

@layer components {
  .chip {
    @apply px-2 py-1 h-8 rounded-lg tracking-wide font-medium text-sm;
  }
  .card {
    @apply rounded-lg relative;
  }
  .card-body {
    @apply p-4;
  }
  .card-right-content {
    @apply absolute top-0 right-0 mt-4 mr-4;
  }
}
