.navigation ul li.active a .icon {
  transform: translateY(-35px);
}
.navigation ul li.active a .text {
  transform: translateY(20px);
  opacity: 1;
}
.navigation ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(90px * 0));
}
.navigation ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(90px * 1));
}
.navigation ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(90px * 2));
}
.navigation ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(90px * 3));
}

.indicator {
  content: " ";
}

.indicator::before {
  content: " ";
  background-color: transparent;
  width: 20px;
  height: 20px;
  left: -20px;
  position: absolute;
  top: 20%;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0px 10px 0px 0px #4e7a80;
  -moz-box-shadow: 0px 10px 0px 0px #4e7a80;
  box-shadow: 0px 10px 0px 0px #4e7a80;
}

.indicator::after {
  content: " ";
  background-color: transparent;
  width: 20px;
  height: 20px;
  right: -20px;
  position: absolute;
  top: 20%;
  border-bottom-left-radius: 20px;
  -webkit-box-shadow: 0px 10px 0px 0px #4e7a80;
  -moz-box-shadow: 0px 10px 0px 0px #4e7a80;
  box-shadow: 0px 10px 0px 0px #4e7a80;
}

.swal2-style {
  z-index: 9999 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #FF9600;
  border-radius: 10px;
}
